import React from "react"
import { Link } from "gatsby"
import SEO from "../components/seo"

import SettingsLayout from "../components/settings-layout"
import Tabs from "../components/tabs"

export default ({data}) => {
  return (
    <SettingsLayout>
      <SEO title="Settings" />

      <div className="head-title">
        <h2>
          About & User settings
        </h2>
      </div>

        <div className="head-body">
          <p>
            I'm working on a user account system that will let Diagram readers follow topics, people, and events.
          </p>
          <p>
            This page will be updated soon.
          </p>
        </div>

    </SettingsLayout>
  )
}