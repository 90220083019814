import { Link } from "gatsby"
import React from "react"

export default props =>
  <div className="types-tabs">
  	<Link to={props.tab1url} activeClassName="active" className={props.tab1class}>
	    <div className="tab">
	      {props.tab1}
	    </div>
    </Link>
    <Link to={props.tab2url} activeClassName="active" className={props.tab2class}>
	    <div className="tab">
	      {props.tab2}
	    </div>
    </Link>
  </div>